import { IPayLoadGetList } from 'app/common/interface'

export const ProfileKeys = {
  allMyVoucher: ['MyVoucher'] as const,
  allReferralVoucher: ['ReferralVoucher'] as const,
  listMyVouchers: () => [...ProfileKeys.allMyVoucher, 'MyVouchers'] as const,
  listMyVoucher: (filter?: IPayLoadGetList) =>
    [...ProfileKeys.listMyVouchers(), { filter }] as const,
  listReferralVouchers: () =>
    [...ProfileKeys.allReferralVoucher, 'ReferralVouchers'] as const,
  listReferralVoucher: (filter?: IPayLoadGetList) =>
    [...ProfileKeys.listReferralVouchers(), { filter }] as const,
}
