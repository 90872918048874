import R from 'app/assets/R'
import { FunctionPermissionEnum } from 'app/common/permission-module'
import { IGroupReport } from 'app/components/common/BaseLayoutReport/type'
import {
  MARKETING_CAMPAIGN_PATH,
  MARKETING_IMPORT_PLAN,
  MARKETING_LIST_REFERRAL_CODE_PATH,
  MARKETING_REFERER_REPORT_PATH,
  MARKETING_REFERRAL_VOUCHER_USER_STATISTICS,
  MARKETING_REPORT_001_FULL_PATH,
  MARKETING_REPORT_006_FULL_PATH,
  MARKETING_REPORT_007_FULL_PATH,
  MARKETING_REPORT_008_FULL_PATH,
  MARKETING_REPORT_009_FULL_PATH,
  MARKETING_REPORT_010_FULL_PATH,
  MARKETING_REPORT_011_FULL_PATH,
  LIMIT_DISCOUNT_MANAGEMENT_PATH,
  REPORT_021_FULL_PATH,
} from 'app/components/router/route-path'
import { DateUtil } from 'parkway-web-common'

export const data: IGroupReport[] = [
  {
    id: 1,
    titleI18nKey: R.strings.report_group_import_data,
    reports: [
      {
        id: 11,
        label: '001',
        descriptionI18nKey: R.strings.marketing_plan,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: MARKETING_IMPORT_PLAN,
        permission: FunctionPermissionEnum.IMPORT_MARKETING_PLAN,
      },
    ],
  },
  {
    id: 2,
    titleI18nKey: R.strings.report_group_report,
    reports: [
      {
        id: 21,
        label: '001',
        descriptionI18nKey: R.strings.report_001_description,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: MARKETING_REPORT_001_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_1,
      },
      {
        id: 22,
        label: '006',
        descriptionI18nKey: R.strings.report_006_description,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: MARKETING_REPORT_006_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_6,
      },
      {
        id: 7,
        label: '007',
        descriptionI18nKey: R.strings.report_007_description,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: MARKETING_REPORT_007_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_7,
      },
      {
        id: 8,
        label: '008',
        descriptionI18nKey: R.strings.report_008_description,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: MARKETING_REPORT_008_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_8,
      },
      {
        id: 9,
        label: '009',
        descriptionI18nKey: R.strings.report_009_description,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: MARKETING_REPORT_009_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_9,
      },
      {
        id: 10,
        label: '010',
        descriptionI18nKey: R.strings.report_010_description,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: MARKETING_REPORT_010_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_10,
      },
      {
        id: 11,
        label: '011',
        descriptionI18nKey: R.strings.report_011_description,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: MARKETING_REPORT_011_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_11,
      },
      {
        id: 12,
        label: '017',
        descriptionI18nKey: R.strings.referer_report,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: MARKETING_REFERER_REPORT_PATH,
        // permission: FunctionPermissionEnum.REPORT_READ_REPORT_17
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_17,
      },
      {
        id: 13,
        label: '018',
        descriptionI18nKey: R.strings.referral_voucher_code_list,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: MARKETING_LIST_REFERRAL_CODE_PATH,
        permission: FunctionPermissionEnum.READ_LIST_VOUCHER_CODE,
      },
      {
        id: 14,
        label: '019',
        descriptionI18nKey: R.strings.referral_voucher_user_statistics,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: MARKETING_REFERRAL_VOUCHER_USER_STATISTICS,
        permission: FunctionPermissionEnum.READ_LIST_VOUCHER_CODE,
      },
      {
        id: 45,
        label: '021',
        descriptionI18nKey: R.strings.report_21,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: REPORT_021_FULL_PATH,
        permission: FunctionPermissionEnum.REPORT_READ_REPORT_21,
      },
    ],
  },
  {
    id: 3,
    titleI18nKey: R.strings.promotion_management,
    reports: [
      {
        id: 31,
        label: '001',
        descriptionI18nKey: R.strings.discount_campaign_management,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: MARKETING_CAMPAIGN_PATH,
        permission: FunctionPermissionEnum.READ_DISCOUNT_CAMPAIGN,
      },
      {
        id: 32,
        label: '002',
        descriptionI18nKey: R.strings.limit_discount_management,
        updateAt: DateUtil.formatDDMMYYY(new Date()),
        path: LIMIT_DISCOUNT_MANAGEMENT_PATH,
        // permission: FunctionPermissionEnum.READ_LIMIT_DISCOUNT_MANAGEMENT,
      },
    ],
  },
]
