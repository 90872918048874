import R from 'app/assets/R'
import { SvgCopyOutlineIcon, SvgLogoVerticalIcon } from 'app/assets/svg-assets'
import { BaseCol } from 'app/components/common/BaseCol'
import { BasePagination } from 'app/components/common/BasePagination'
import { BaseRow } from 'app/components/common/BaseRow'
import BaseText from 'app/components/common/BaseText'
import { IRefModal, ModalComponent } from 'app/components/common/ModalComponent'
import { notificationController } from 'app/controllers/notification-controller'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BackgroundWrapper,
  BaseTitle,
  BottomDivider,
  CodeRow,
  CollapseWrapper,
  DescriptionText,
  Divider,
  DividerCol,
  InfoCol,
  LogoCol,
  PaginationRow,
  RootContainer,
  TextCodeTitle,
  TextCodeValue,
  TextExpiredDateTitle,
  TextExpiredDateValue,
  TextServiceButton,
  TextServiceTitle,
  TextStatusUsed,
  TextStatusUnused,
  TopDivider,
  BaseTableTreatment,
} from './styles'
import { IDiscountVoucher } from 'app/api/marketing/discount-voucher/model'
import moment from 'moment'
import { EVoucherStatus, EVoucherStatusName } from './type'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { useHook } from './hook'
import { BaseSpace } from 'app/components/common/BaseSpace'

export const VoucherCard = (props: {
  data: IDiscountVoucher
  codeTitle: string
}) => {
  const { data, codeTitle = '' } = props
  const { dataTable, pagination, handleTableChange, columns } = useHook(
    data?.treatmentInfos || [],
  )
  const { t } = useTranslation()
  const [isShowCopy, setIsShowCopy] = useState(false)
  const modalRef = useRef<IRefModal>({})

  const statusName = t(EVoucherStatusName[data?.status || ''])
  const codeTextDecoration =
    data?.status === EVoucherStatus?.USED ? 'line-through' : 'auto'
  const expiredDate = useMemo(() => {
    if (!data?.expiredAt) {
      return t(R.strings.no_expired_date)
    }
    return moment(data?.expiredAt).isBefore(moment())
      ? moment(data?.expiredAt)?.format('dd/MM/YYYY')
      : t(R.strings.out_date)
  }, [data?.expiredAt])

  const handleCopyClick = () => {
    navigator.clipboard.writeText('test copy')
    notificationController.success({ message: 'Copied to clipboard' })
  }
  const onMouseOver = (e: any) => {
    if (e?.type === 'mouseover') {
      setIsShowCopy(true)
    } else {
      //e?.type === 'mouseout'
      setIsShowCopy(false)
    }
  }

  const handleSeeDetailOnClick = (e: any) => {
    e.stopPropagation()
    modalRef?.current?.open && modalRef?.current?.open()
  }

  return (
    <RootContainer align={'middle'} id="root-card">
      <LogoCol $backgroundColor={convertedVariables.neutralBlack2Color}>
        <SvgLogoVerticalIcon />
      </LogoCol>
      <DividerCol>
        <TopDivider />
        <Divider $height={document.getElementById('root-card')?.clientHeight} />
        <BottomDivider />
      </DividerCol>
      <InfoCol $backgroundColor={convertedVariables.neutralBlack2Color}>
        <BaseRow justify={'space-between'}>
          <BaseCol>
            <TextCodeTitle>{codeTitle}</TextCodeTitle>
            <CodeRow
              onClick={handleCopyClick}
              onMouseOut={onMouseOver}
              onMouseOver={onMouseOver}
            >
              <BaseCol>
                <TextCodeValue $textDecoration={codeTextDecoration}>
                  {data?.code ?? ''}
                </TextCodeValue>
              </BaseCol>
              {isShowCopy && <SvgCopyOutlineIcon />}
            </CodeRow>
          </BaseCol>
          <BaseCol>
            {data?.status === EVoucherStatus?.USED ? (
              <TextStatusUsed>{statusName}</TextStatusUsed>
            ) : (
              <TextStatusUnused>{statusName}</TextStatusUnused>
            )}
          </BaseCol>
        </BaseRow>
        <BaseRow style={{ marginTop: 12 }}>
          <BaseCol style={{ flex: 1 }}>
            <TextServiceTitle>{t(R.strings.service_apply)}</TextServiceTitle>
            <ModalComponent
              buttonOpenCustom={
                <TextServiceButton onClick={handleSeeDetailOnClick}>
                  {t(R.strings.see_detail)}
                </TextServiceButton>
              }
              isShowConfirm={false}
              isShowCancel={false}
              isShowLineBottom={false}
              titleModal={R.strings.detail_voucher}
              ref={modalRef}
              widthModal={1000}
              renderContent={
                <BaseSpace>
                  {data?.description && (
                    <CollapseWrapper
                      defaultActiveKey={['description']}
                      items={[
                        {
                          key: 'description',
                          label: (
                            <BaseTitle>{t(R.strings.description)}</BaseTitle>
                          ),
                          style: {
                            alignItems: 'center',
                          },
                          children: (
                            <BackgroundWrapper>
                              <DescriptionText>
                                {data?.description}
                              </DescriptionText>
                            </BackgroundWrapper>
                          ),
                        },
                      ]}
                      expandIconPosition="right"
                    />
                  )}
                  {dataTable?.length > 0 && (
                    <BaseTableTreatment
                      columns={columns}
                      dataSource={dataTable}
                      pagination={pagination}
                      onChange={handleTableChange}
                      rowClassName="row-overwrite-style"
                      scroll={{
                        x: true,
                      }}
                    />
                  )}
                </BaseSpace>
              }
            />
          </BaseCol>
          <BaseCol style={{ flex: 1 }}>
            <TextExpiredDateTitle>
              {t(R.strings.using_date)}
            </TextExpiredDateTitle>
            <TextExpiredDateValue>{expiredDate}</TextExpiredDateValue>
          </BaseCol>
        </BaseRow>
      </InfoCol>
    </RootContainer>
  )
}
