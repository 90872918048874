import R from 'app/assets/R'

export interface IDataTable {
  [key: string]: string
}
export enum EVoucherStatus {
  USED = 1,
  UNUSED = -1,
  USING = 0,
}
export const EVoucherStatusName = {
  [EVoucherStatus.USED]: R.strings._used,
  [EVoucherStatus.UNUSED]: R.strings.unused,
  [EVoucherStatus.USING]: R.strings.using,
}
