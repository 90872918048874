import { BaseCol } from 'app/components/common/BaseCol'
import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseTableWrapper } from 'app/components/tables/BaseTableReport/styles'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { FONT_SIZE, FONT_WEIGHT, PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const RootContainer = styled(BaseRow)`
  background: var(--white);
  width: 358px;
  border-radius: 8px;
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
`
export const StatusContainer = styled.div``
export const LogoCol = styled(BaseCol)<{ $backgroundColor?: string }>`
  padding: 0px 10px 0px 10px;
  height: fit-content;
`
export const DividerCol = styled(BaseCol)`
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  height: fit-content;
  overflow: hidden;
`
export const Divider = styled.div<{ $height?: number }>`
  border-right: 1px dashed #dadee3;
  width: 0px;
  height: ${props => (props.$height ? props.$height - 12 : 114)}px;
`
export const TopDivider = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background: #fcfcfd;
  margin-top: -6px;
`
export const BottomDivider = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background: #fcfcfd;
  margin-bottom: -6px;
`
export const InfoCol = styled(BaseCol)<{ $backgroundColor?: string }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
`
export const TextCodeTitle = styled(BaseText)`
  font-weight:${FONT_WEIGHT.regular}
  font-size:12px;
  color: #A5ABB3;
`
export const TextCodeValue = styled(BaseText)<{
  $textDecoration: string
}>`
  font-weight: ${FONT_WEIGHT.semibold} !important;
  font-size: 14px !important;
  color: #6d7580;
  margin-top: 4px;
  margin-right: 4px;
  text-decoration: ${props => props?.$textDecoration ?? 'auto'};
`
export const TextServiceTitle = styled(BaseText)`
  font-weight: ${FONT_WEIGHT.regular} !important;
  font-size: 10px !important;
  color: #a5abb3;
`
export const TextServiceButton = styled.div`
  font-weight: ${FONT_WEIGHT.regular} !important;
  font-size: 12px !important;
  color: ${convertedVariables.otherBlueColor};
  margin-top: 4px;
  padding: 0;
  cursor: pointer;
`
export const TextExpiredDateTitle = styled(BaseText)`
  font-weight: ${FONT_WEIGHT.regular} !important;
  font-size: 10px !important;
  color: #a5abb3;
`
export const TextExpiredDateValue = styled(BaseText)`
  font-weight: ${FONT_WEIGHT.regular} !important;
  font-size: 12px !important;
  color: #6d7580;
  margin-top: 4px;
`
export const TextStatusUnused = styled(BaseCol)`
  font-weight: ${FONT_WEIGHT.medium} !important;
  font-size: 12px !important;
  background: #e8f6f9;
  color: #629daa;
  padding: 4px 12px;
  border-radius: 100px;
`
export const TextStatusUsed = styled(BaseCol)`
  font-weight: ${FONT_WEIGHT.medium} !important;
  font-size: 12px !important;
  background: #fcfcfd;
  border: 1px solid var(--Neutral-Black8, #ebeef2);
  color: ${convertedVariables.primaryColor};
  opacity: 0.9;
  padding: 4px 12px;
  border-radius: 100px;
`
export const PaginationRow = styled(BaseRow)`
  padding: 16px 20px 16px 20px;
  background: var(--white);
`
export const CodeRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`
export const CollapseWrapper = styled(BaseCollapse)<{ $bottomRadius?: number }>`
  width: 100%;
  border: 1px solid ${convertedVariables.neutralBlack9Color};
  .ant-collapse-item {
    border-bottom: 0px;
  }

  .ant-collapse-content {
    background: var(--white) !important;
    .ant-collapse-content-box {
      padding: 0px !important;
      border-bottom-left-radius: ${props =>
        props?.$bottomRadius ?? 0}px !important;
      border-bottom-right-radius: ${props =>
        props?.$bottomRadius ?? 0}px !important;
    }
  }

  .ant-collapse-header {
    align-items: center !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    background-color: var(--white);
  }

  .ant-collapse-header[aria-expanded='true'] {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .header-content {
    padding: ${PADDING.md};
    background: var(--white);
  }

  .list-item-content-wrapper {
    padding: ${PADDING.md};
  }
`
export const BaseTitle = styled(BaseText)`
  font-size: ${FONT_SIZE.xs}!important;
  font-weight: ${FONT_WEIGHT.medium}!important;
  opacity: 0.7;
`
export const BackgroundWrapper = styled(BaseSpace)`
  background: var(--white);
  border-radius: 0px;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
`
export const BaseTableTreatment = styled(BaseTableWrapper)`
  .ant-table-thead > tr > th {
    border: none !important;
  }
  .ant-table-thead > tr > th:first-child {
    text-align: left !important;
    border-start-start-radius: 0 !important;
  }
  .ant-table-thead > tr > th:last-child {
    text-align: left !important;
    border-start-end-radius: 0 !important;
  }
  .ant-table-cell {
    border: none !important;
    border-bottom: 0.5px solid var(--neutral-black-2-color) !important;
  }
  .ant-table-thead .ant-table-cell {
    font-size: ${FONT_SIZE.xxs}!important;
    color: var(--primary-color) !important;
    font-weight: ${FONT_WEIGHT.semibold}!important;
    opacity: 0.7 !important;
  }
  .ant-table-tbody .ant-table-cell {
    border: none !important;
    border-bottom: 1px solid var(--neutral-black-2-color) !important;
  }
`
export const DescriptionText = styled(BaseText)`
  font-size: ${FONT_SIZE.xs}!important;
`
export const BaseTextRegionName = styled(BaseText)`
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.medium} !important;
  opacity: 0.5;
`
export const BaseTextRegionPrice = styled(BaseText)`
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.medium} !important;
  color: ${convertedVariables.statesGreenColor};
  margin-right: 24px;
`
