import { IDiscountVoucher } from 'app/api/marketing/discount-voucher/model'
import { requestMyReferral, requestMyVoucher } from 'app/api/profile'
import { ProfileMessageCode } from 'app/api/profile/constant'
import { IPayLoadGetList } from 'app/common/interface'
import { getToken } from 'app/service/storage/storage-service'
import { isEqual } from 'lodash'
import { ResponseType } from 'parkway-web-common'
import { useInfiniteQuery } from 'react-query'
import { IResponseQueryList } from '../model/common'
import { ProfileKeys } from '../query-key/profile'

export function useGetMyVoucherList(params?: IPayLoadGetList, enabled = true) {
  const token = getToken()

  return useInfiniteQuery<IResponseQueryList<IDiscountVoucher[]>, undefined>(
    ProfileKeys.listMyVoucher(params),
    async () => {
      try {
        const res: ResponseType<IDiscountVoucher[]> = await requestMyVoucher(
          params,
        )

        if (
          isEqual(
            res?.msgcode,
            ProfileMessageCode.ProfileManagement.successGetData,
          )
        ) {
          const data: IDiscountVoucher[] = res?.data || []
          return {
            data,
            total: data.length ?? 0,
            totalItem: res?.metadata?.totalItem ?? 0,
          }
        }
        return {
          data: [],
          total: 0,
          totalItem: res?.metadata?.totalItem ?? 0,
        }
      } catch (error: any) {
        return {
          data: [],
          total: 0,
          totalItem: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}

export function useGetReferralVoucherList(
  params?: IPayLoadGetList,
  enabled = true,
) {
  const token = getToken()
  return useInfiniteQuery<IResponseQueryList<IDiscountVoucher[]>, undefined>(
    ProfileKeys.listReferralVoucher(params),
    async () => {
      try {
        const res: ResponseType<IDiscountVoucher[]> = await requestMyReferral(
          params,
        )

        if (
          isEqual(
            res?.msgcode,
            ProfileMessageCode.ProfileManagement.successGetData,
          )
        ) {
          const data: IDiscountVoucher[] = res?.data || []
          return {
            data,
            total: data.length ?? 0,
            totalItem: res?.metadata?.totalItem ?? 0,
          }
        }
        return {
          data: [],
          total: 0,
          totalItem: res?.metadata?.totalItem ?? 0,
        }
      } catch (error: any) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}
