import { DeleteOutlined, RedoOutlined } from '@ant-design/icons'
import { BaseAvatar } from 'app/components/common/BaseAvatar'
import { BaseButton } from 'app/components/common/BaseButton'
import { Button } from 'app/components/common/BaseButton/styles'
import { BaseCollapse } from 'app/components/common/BaseCollapse'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseImage } from 'app/components/common/BaseImage'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { SectionBase } from 'app/components/common/SectionBase'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { BaseSelectSearch } from 'app/components/common/selects/SelectSearch'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { FONT_SIZE, FONT_WEIGHT, PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const BaseWrapper = styled.div`
  background-color: var(--states-orange-color);
  color: var(--background-color);
`

export const BaseActionWrapper = styled.div`
  padding: 0px ${PADDING.md};
  display: flex;
  gap: 1rem;
  flex-direction: column;
  padding-bottom: ${PADDING.md};
`

export const WrapperButton = styled(Button)`
  margin-bottom: 1rem;
  margin-top: 1rem;
`

export const ButtonSubmit = styled(BaseButton)`
  min-width: 120px;
  background-color: var(--other-blue-color);
  color: var(--background-color);
  border-color: var(--other-blue-color);
  &:hover {
    color: var(--background-color) !important;
    border-color: var(--other-blue-color);
  }
`
export const ButtonCancel = styled(BaseButton)`
  min-width: 120px;
`

export const BaseFormWrapper = styled(BaseForm)`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`

export const ButtonStyled = styled.a`
  color: var(--states-red-color);
  width: 32px;
  height: 32px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const RefreshButton = styled(RedoOutlined)`
  width: 32px;
  height: 32px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LabelWrapper = styled(BaseRow)`
  color: var(--main-2-color);
`

export const RequireDot = styled.div`
  color: var(--states-red-color);
`
export const DashLine = styled(BaseDivider)``

export const TitleForm = styled.div`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.bold};
`

export const TitleFormList = styled.h3`
  margin-bottom: 1rem;
`

export const FormItem = styled(BaseForm.Item)`
  .ant-form-item {
    margin-bottom: 0px !important;
  }
  .ant-form-item-control-input {
    min-height: unset;
  }
`

export const FormInput = styled(BaseInput)``

export const SelectBaseStyled = styled(BaseSelect)``

export const SelectSearchBaseStyled = styled(BaseSelectSearch)``

export const DegreeWrapper = styled(BaseSpace)`
  width: 100%;
  .tag {
    background-color: #f8f8f8;
    border-radius: 100px;
    padding: 2px 1rem;
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.semibold};
  }

  .excellent {
    background-color: #fffbed;
    color: #fa9f34;
  }

  .good {
    background-color: #ecfdf2;
    color: #28ba59;
  }

  .average_good {
    background-color: #e8f6f9;
    color: #629daa;
  }

  .average {
    background-color: #e8f6f9;
    color: #629daa;
  }

  .below_average {
    background-color: #e8f6f9;
    color: #629daa;
  }

  .weak {
    background-color: #ffe6e6;
    color: #ff7373;
  }
`
export const BtnCustom = styled(Button)`
  justify-content: flex-start;
`

export const DatePicker = styled(BaseDatePicker)`
  min-width: 160px;
`
export const DeleteButton = styled(DeleteOutlined)`
  color: var(--states-red-color);
  width: 32px;
  height: 32px;
  background-color: #fff;
  display: flex;
`

// new styles

export const SectionBaseWrapper = styled(SectionBase)`
  padding: ${PADDING.xl} !important;
`

export const AvatarProfile = styled(BaseAvatar)`
  width: 120px !important;
  height: 120px !important;
  border-radius: 60px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: ${FONT_SIZE.xxxxl};
  font-weight: ${FONT_WEIGHT.semibold};
`

export const TextNameProfile = styled.div`
  color: var(--primary-color);
  font-weight: ${FONT_WEIGHT.semibold};
  font-size: ${FONT_SIZE.xl};
`

export const TextJobTitleProfile = styled.div`
  color: var(--primary-color);
  font-weight: ${FONT_WEIGHT.regular};
  font-size: ${FONT_SIZE.md};
`

export const StatusChip = styled.div`
  background-color: #5585f4;
  padding: ${PADDING.xxxxs} ${PADDING.md};
  color: white;
  border-radius: ${PADDING.xxxl};
  text-align: center;
  width: fit-content;
  margin-top: ${PADDING.xxxxs};
`

export const TextValue = styled.div`
  color: var(--primary-color);
  font-weight: ${FONT_WEIGHT.semibold};
  font-size: ${FONT_SIZE.md};
`

export const IconCopy = styled(BaseImage)`
  cursor: pointer;
`

export const Line = styled(BaseDivider)`
  margin: 0px;
`

export const TitleText = styled.div`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
`

export const TitleTypeSkill = styled.div`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.semibold};
  opacity: 0.7;
`

export const TextDegreeName = styled.div`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
`

export const TextStartDateDegree = styled.div`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
  opacity: 0.7;
`

export const TextSeeClassification = styled.div`
  color: #216ae2;
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_SIZE.md};
  cursor: pointer;
`
export const ItemWorkInfo = styled(BaseRow)<{
  $isDisable?: boolean
  $count?: number
}>`
  opacity: ${props => (props?.$isDisable ? 0.5 : 1)};
  width: 100%;
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: ${props =>
      props?.$isDisable ? 'var(--primary-color)' : '#28BA59'};
      opacity: ${props => (props?.$isDisable ? 0.5 : 1)};
      margin-top: ${PADDING.xxxxs};
  }
  .line-first {
    width: 1px;
    height: ${props =>
      props?.$count && props?.$count > 1
        ? 48 + (props?.$count - 1) * 105
        : 48}px;
    background-color: ${props =>
      props?.$isDisable ? 'var(--primary-color)' : '#28BA59'};
    margin-top: ${PADDING.xxs};
    opacity: ${props => (props?.$isDisable ? 0.5 : 1)}
  }
  .label-text {
    color: #545D69 // need change
    font-size: ${FONT_SIZE.xs};
    font-weight: ${FONT_WEIGHT.regular};
    opacity: 0.7;
  }
  .name-work-info {
    text-align: start;
    padding: unset !important;
    font-weight: ${FONT_WEIGHT.semibold};
    font-size: ${FONT_SIZE.lg}
  }
  .value-work-info {
    text-align: start;
    padding: unset !important;
  }
  .text-status {
    color: #28BA59;
  }
`
export const CollapseWrapper = styled(BaseCollapse)<{ $bottomRadius?: number }>`
  width: 100%;
  border: 1px solid ${convertedVariables.neutralBlack9Color};

  .ant-collapse-item {
    border-bottom: 0px;
  }

  .ant-collapse-content {
    border-top: 1px solid ${convertedVariables.neutralBlack9Color};
    background: #fcfcfd !important;
    .ant-collapse-content-box {
      padding: 0px !important;
      border-bottom-left-radius: ${props =>
        props?.$bottomRadius ?? 0}px !important;
      border-bottom-right-radius: ${props =>
        props?.$bottomRadius ?? 0}px !important;
    }
  }

  .ant-collapse-header {
    align-items: center !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    background-color: var(--white);
  }

  .ant-collapse-header[aria-expanded='true'] {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .header-content {
    padding: ${PADDING.md};
    background-color: #f9fafb;
  }

  .list-item-content-wrapper {
    padding: ${PADDING.md};
  }
`
export const BaseTitle = styled(BaseText)`
  font-size: ${FONT_SIZE.xs}!important;
  font-weight: ${FONT_WEIGHT.medium}!important;
  opacity: 0.7;
`
export const BackgroundWrapper = styled(BaseSpace)`
  background: #fcfcfd;
  border-radius: 0px;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
`
export const PaginationRow = styled(BaseRow)`
  padding: 16px 20px 16px 20px;
  background: var(--white);
`
