import { usePagination } from 'app/hook'
import { useGetMyVoucherList } from 'app/react-query/hook/profile'
import { Pagination, formatMoney, initialPagination } from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataTable } from './type'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { IDiscountVoucher } from 'app/api/marketing/discount-voucher/model'
import BaseText from 'app/components/common/BaseText'
import R from 'app/assets/R'
import { ITreatmentInTable } from 'app/containers/Marketing/DiscountCampaignManagement/CreateCampaign/type'
import { IRegionalPrice } from 'app/api/marketing/discount-campaign/model'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { convertToRegionName } from 'app/common/helpers'
import { BaseTextRegionName, BaseTextRegionPrice } from './styles'
import { BaseSpace } from 'app/components/common/BaseSpace'

export const useHook = (treatmentList: ITreatmentInTable[]) => {
  const { t } = useTranslation()

  const [pagination, setPagination] = useState<Pagination>(initialPagination)
  const { flattenDataList } = usePagination()

  const data = useMemo(() => {
    return treatmentList?.map((item, index) => {
      return {
        ...item,
      } as IDataTable
    })
  }, [treatmentList])

  const renderRegionPrice = (array: IRegionalPrice[]) => {
    const renderItem = (item: IRegionalPrice, index: number) => {
      return (
        <BaseCol key={`regional-price-${index}`}>
          <BaseRow>
            <BaseCol>
              <BaseTextRegionName>
                {convertToRegionName(Number(item?.region))}
              </BaseTextRegionName>
            </BaseCol>
            <BaseCol>
              <BaseTextRegionPrice>
                {item?.price ? `: ${formatMoney(item?.price, 'đ')}` : '-'}
              </BaseTextRegionPrice>
            </BaseCol>
          </BaseRow>
        </BaseCol>
      )
    }

    return <>{array?.map(renderItem)}</>
  }

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IDataTable>({
        title: '#',
        key: '#',
        classNameWidthColumnOverwrite: 'small-column',
        render: (_, record: ITreatmentInTable, index) => {
          return (
            <BaseText children={index + 1} fontWeight="medium" opacity="0.5" />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.service),
        key: 'clinic',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record: ITreatmentInTable) => {
          return (
            <BaseSpace size={4}>
              <BaseText
                children={record?.name}
                fontWeight="semibold"
                opacity="0.9"
                textAlign="left"
              />
              <BaseRow>
                {renderRegionPrice(record?.regionalPrice || [])}
              </BaseRow>
            </BaseSpace>
          )
        },
      }),
    ]
    return option
  }, [t])

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  return {
    dataTable: data,
    pagination,
    setPagination,
    handleTableChange,
    columns,
  }
}
