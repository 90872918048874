// Message code define
export const ProfileMessageCode = {
  ProfileManagement: {
    successGetData: '20000',
    successPutData: '20000',
    successDeleteData: '20000',
    successPostData: '20001',
  },
  Doctor: {
    successGetData: '80000',
  },
}

// Endpoint
export const ProfileEndPoint = {
  ProfileManagement: {
    request: 'hr/v1/profile',
    updateMessageToken: 'hr/v1/profile/:id/messaging-token',
  },
  export: {
    profile: '/hr/v1/profile/export',
  },
  UserInfo: {
    request: 'hr/v1/profile/user-info',
  },
  DoctorManagement: {
    request: 'hr/v1/profile/find',
  },
  Doctor: {
    request: 'olddb/v1/user/doctors',
  },
  EmployeeVoucher: {
    my: 'olddb/v1/discount-voucher/my/voucher',
    referral: 'olddb/v1/discount-voucher/my/referral',
  },
}
